import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../../components/Layout';
import styled from 'styled-components';

// Videos
import snowshoeVideo from './images/cypress-mountain-snowshoe.webm';
import { Link } from 'gatsby';

const Container = styled.div`
  h4 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    line-height: 1.75rem;
    color: #475569;
  }

  p {
    margin: 1.4rem 0;
    font-size: 1.2rem;
    line-height: 1.4;
    text-align: justify;
  }
`;

const Video = styled.video`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`;

const List = styled.ul`
  list-style: square;
  font-size: 1.2rem;
  line-height: 1.2;
`;

export default function () {
  return (
    <Layout title="January, February, March 2022">
      <Container>
        <p>
          One of my new years resolutions was to write more about what I’m
          doing, now that I don’t use services like Instagram very often. So,
          here we go. Another of my resolutions was to keep a TIL blog, which
          I’m trying to keep updated <Link to="/today-i-learned">here</Link>.
          So, that’s good progress on two resolutions.
        </p>
        <p>
          The first three months of this year have been AWESOME - and most
          importantly, we survived our first winter in Canada! 🎉❄️🇨🇦
        </p>
        <p>
          I was lucky enough to get two weeks of{' '}
          <a href="https://www.oxfordlearnersdictionaries.com/definition/english/vacation_1">
            vacation
          </a>{' '}
          from EA over the Christmas period. We started the year off with a walk
          in the beautiful (and icey) Stanley Park:
        </p>
        <StaticImage
          alt="Steven in Stanley Park"
          src={'./images/steven-stanley-park-winter.jpeg'}
          title="Steven in Stanley Park"
        />
        <StaticImage
          alt="Lifeguards in Stanley Park, January 2022"
          src={'./images/stanley-park-lifeguards-winter.jpeg'}
          title="Lifeguards in Stanley Park, January 2022"
        />
        <p>
          Stanley Park was a nice warm up for walking around in the snow, but we
          decided to crank it up a gear and ventured to the top of Cypress
          Mountain for some snowshoeing:
        </p>
        <div style={{ display: 'flex' }}>
          <StaticImage
            alt="Cypress Mountain coach ticket"
            src={'./images/cypress-mountain-coach-ticket.jpeg'}
            title="Cypress Mountain coach ticket"
          />
          <StaticImage
            alt="Cypress Mountain snowshoe trail maker"
            src={'./images/cypress-mountain-snowshoe-trail-marker.jpeg'}
            title="Cypress Mountain snowshoe trail maker"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <StaticImage
              alt="Cypress Mountain trees"
              src={'./images/cypress-mountain-trees-1.jpeg'}
              title="Cypress Mountain trees"
            />
            <StaticImage
              alt="Cypress Mountain trees"
              src={'./images/cypress-mountain-trees-2.jpeg'}
              title="Cypress Mountain trees"
            />
          </div>
          <div>
            <Video autoPlay loop muted width="360" height="640">
              <source src={snowshoeVideo} type="video/webm" />
            </Video>
          </div>
        </div>
        <StaticImage
          alt="Steven Frostwick snowshoes Cypress Mountain, Vancouver"
          src={'./images/steven-frostwick-snowshoes-cypress-mountain.jpeg'}
          title="Steven Frostwick snowshoes Cypress Mountain, Vancouver"
        />

        <p>
          Then - disaster struck. I finally succumbed to COVID-19, which was way
          worse than I expected it to be 🤧, but it did bond us with our new
          neighbours in Canada who helped us pull through 🥰
        </p>
        <p>
          By the end of that bed-ridden, orange juice fuelled week and a half it
          was time to stretch out our legs once again at Wreck Beach. Which it
          turns out has over 500 wooden steps to get down to. And also is a{' '}
          <a href="https://www.writingtips.cc/difference-between-naturalist-and-naturist/">
            naturist
          </a>{' '}
          beach.
        </p>
        <p>
          Although given it was around 0°C, we’ll have to visit this beach again
          later in the year if we want to enjoy all it has to offer 🤔
        </p>
        <StaticImage
          alt="Wreck Beach sign"
          src={'./images/wreck-beach-sign.JPG'}
          title="Wreck Beach sign"
        />
        <StaticImage
          alt="Steven Frostwick with binoculars at Wreck Beach"
          src={'./images/wreck-beach-binoculars.jpeg'}
          title="Steven Frostwick with binoculars at Wreck Beach"
        />
        <p>
          The calendar flipped over to February and we were blessed with a
          beautiful sunny day and clear skies when we kayaked at Deep Cove in
          North Vancouver, which was my Christmas present from Kat.
        </p>
        <p>It is a stunning place to have on our doorstep:</p>
        <StaticImage
          alt="Deep Cove"
          src={'./images/deep-cove.jpeg'}
          title="Deep Cove"
        />
        <div style={{ display: 'flex' }}>
          <StaticImage
            alt="Deep Cove kayak map"
            src={'./images/deep-cove-kayak-map.jpeg'}
            title="Deep Cove kayak map"
          />
          <StaticImage
            alt="Kat in kayak"
            src={'./images/kayak-kat.jpeg'}
            title="Kat in kayak"
          />
        </div>
        <p>
          The best part about kayaking at Deep Cove? Landing on Racoon Island
          and eating a donut from the{' '}
          <a href="https://dailyhive.com/vancouver/kate-winslet-honeys-doughnuts">
            Kate-Winslet-approved bakery Honey’s Doughnuts
          </a>
          , which is so popular it has a line down the street. But luckily for
          us our guide brought a bag on our trip.
        </p>
        <StaticImage
          alt="Steven and Kat in a kayak"
          src={'./images/steven-frostwick-and-kat-kayak-deep-cove.jpeg'}
          title="Steven and Kat in a kayak"
        />
        <StaticImage
          alt="Racoon Island kayak doughnut"
          src={'./images/deep-cove-kayak-doughnut.jpeg'}
          title="Racoon Island kayak doughnut"
        />
        <p>
          At the end of the month, we ticked another Canadian milestone off our
          list when we went to our first <s>ice</s> hockey game and saw the
          Vancouver Canucks beat the Calgary Flames to end their 10 game winning
          streak.
        </p>
        <p>
          The whole game was wilder than I thought it could be. At one point
          Michael Bublé was beaming down at us from the screens above the rink,
          so it couldn’t have got much more Canadian than that. Well, we didn’t
          have poutine (yet).
        </p>
        <StaticImage
          alt="Vancouver Canucks win"
          src={'./images/canucks-win.jpeg'}
          title="Vancouver Canucks win"
        />
        <p>
          By the time March came around we were ready to head back to North
          Vancouver, and ventured to the Lynn Valley. We started by crossing a
          suspension bridge next to a waterfall and then linked up with the
          Baden Powell Trail.
        </p>
        <div style={{ display: 'flex' }}>
          <StaticImage
            alt="Steven Frostwick on suspension bridge in Lynn Valley"
            src={'./images/steven-frostwick-lynn-valley-suspension-bridge.jpeg'}
            title="Steven Frostwick on suspension bridge in Lynn Valley"
          />
          <StaticImage
            alt="Steven Frostwick in Lynn Valley (1)"
            src={'./images/steven-frostwick-lynn-valley-1.jpeg'}
            title="Steven Frostwick in Lynn Valley (1)"
          />
        </div>
        <StaticImage
          alt="Steven Frostwick in Lynn Valley (2)"
          src={'./images/steven-frostwick-lynn-valley-2.jpeg'}
          title="Steven Frostwick in Lynn Valley (2)"
        />
        <StaticImage
          alt="Steven Frostwick at the Baden Powell Trail sign"
          src={'./images/steven-frostwick-baden-powell-trail.jpeg'}
          title="Steven Frostwick at the Baden Powell Trail sign"
        />
        <p>
          🎶 We were lucky enough to see Nick Cave and Warren Ellis on their
          North America Tour at the Queen Elizabeth Theatre, who were both as
          amazing as they always are and we were treated to TWO encores. 🎶
        </p>
        <StaticImage
          alt="Nick Cave, Queen Elizabeth Theatre, Vancouver"
          src={'./images/nick-cave-vancouver.JPG'}
          title="Nick Cave, Queen Elizabeth Theatre, Vancouver"
        />
        <p>
          Then, at the end of March, the unexpected happened. Vancouver was
          glowing pink, every park and every street lined in blossom trees.
        </p>
        <div style={{ display: 'flex' }}>
          <StaticImage
            alt="Blossom in Vancouver (1)"
            src={'./images/vancouver-blossom-1.jpeg'}
            title="Blossom in Vancouver (1)"
          />
          <StaticImage
            alt="Blossom in Vancouver (2)"
            src={'./images/vancouver-blossom-2.jpeg'}
            title="Blossom in Vancouver (2)"
          />
        </div>
        <section>
          <h4>Books I read</h4>
          <List>
            <li>The Effective Engineer - Edmond Lau</li>
          </List>
          <span>This is not good enough, I must read more books 😱</span>
        </section>

        <section>
          <h4>Films I watched</h4>
          <List>
            <li> Yes, God, Yes (2019)</li>
            <li> Licorice Pizza (2021)</li>
            <li> School of Rock (2003)</li>
            <li> Red Rocket (2021) ⭐️</li>
            <li> Before Sunrise (1995)</li>
            <li> Compartment No. 6 (2021) ⭐️</li>
            <li> Clueless (1995)</li>
            <li> Turning Red (2022)</li>
            <li> Mean Girls (2004)</li>
            <li> Spring Breakers (2012)</li>
          </List>
        </section>

        <section>
          <h4>Favourite new music</h4>
          <List>
            <li>
              <a
                href="https://open.spotify.com/track/0BnepvoveEw7fJIpuwdLT4"
                target="_blank"
              >
                Happy New Year - Let's Eat Grandma
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/track/4ug5wsIcbAPBun8TCKn2t6"
                target="_blank"
              >
                Ur Mum - Wet Leg
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/track/57g6GhJdL87bJ6IUYrwMLF"
                target="_blank"
              >
                God Bless The Animals - Psylhouette
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/track/7uVLrPYrICUJyFHX9vCSal"
                target="_blank"
              >
                The Loser - THUMPER
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/track/5HIGwSKny5nhx20AnFSOD7"
                target="_blank"
              >
                Did You Sleep Last Night? - LAUNDRY DAY
              </a>
            </li>
          </List>
        </section>

        <p>
          Looking forward to the next three months - the end of spring and the
          beginning of summer!
        </p>
      </Container>
    </Layout>
  );
}
